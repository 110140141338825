import { useWindowMessage } from '#imports';
import {
    BoxRevealComplete,
    CardRevealComplete,
    Complete,
    PackRevealComplete,
    Setup,
    StepBoxReveal,
    StepCardsReveal,
    StepIdle,
    StepPackReveal,
    Tier0,
    Tier1,
    Tier2,
    Tier3,
    Tier4,
    UpdateCard,
    UpdateStep,
} from '@fanatics/resn-api/instant-rips';
import { EventEmitter } from 'eventemitter3';
import { computed, defineComponent, inject, onMounted, provide, ref, shallowRef, watch } from 'vue';

import { gsap } from '@resn/gsap';

import { useParentSearchParams } from '~/components/useParentSearchParams';
import { BOX_DUR, CARDS_DUR, PACK_DUR, PALETTE_THEME } from '~/core/constants';

// import { TIER_PALETTE } from '~/core/constants';

// import { BREAK_STEPS, Break } from '~/types/Break';

const FanaticsKey = 'env';

export const useFanatics = () => {
    const api = inject(FanaticsKey);
    if (!api) {
        throw new Error('useFanatics must be used within a FanaticsProvider');
    }
    return api;
};

const FanaticsProvider = defineComponent({
    setup() {
        const events = new EventEmitter();

        const { sendMessage } = useWindowMessage();

        const box = shallowRef(null);
        const user = shallowRef(null);
        const ready = ref(false);
        const stepBase = ref(StepIdle);
        const step = computed(() => (ready.value ? stepBase.value : StepIdle));
        const cardIndex = ref(0);
        const cards = ref([]);
        // const interactive = ref(false);
        const sweat = ref(false);
        const currentCard = computed(() => cards.value[cardIndex.value]);
        const rerip = ref(false);
        const uiHeightRatio = ref(0.0);
        const sceneOffset = ref(0.0);
        const lightsDown = ref(false);

        const paletteMap = { [Tier0]: 0, [Tier1]: 1, [Tier2]: 2, [Tier3]: 3, [Tier4]: 4 };
        const colorPaletteIndex = ref(0);
        const colorPalette = computed(() => PALETTE_THEME[colorPaletteIndex.value]);
        const setColorPalette = (tier) => {
            colorPaletteIndex.value = paletteMap[tier] || 0;
        };

        useParentSearchParams((params) => {
            if (params.get('sceneOffset')) {
                const val = parseFloat(params.get('sceneOffset'));
                if (typeof val === 'number') sceneOffset.value = val;
            }
        });

        onMounted(() => {
            window.colorPaletteIndex = colorPaletteIndex;
        });

        watch(step, (val) => {
            console.log('FanaticsProvider: Step:', val);

            let duration = 0;
            let messageId = null;

            switch (val) {
                case StepBoxReveal:
                    duration = BOX_DUR;
                    messageId = BoxRevealComplete;
                    break;
                case StepPackReveal:
                    messageId = PackRevealComplete;
                    duration = PACK_DUR;
                    break;
                case StepCardsReveal:
                    duration = CARDS_DUR;
                    messageId = CardRevealComplete;
                    break;

                default:
                    break;
            }

            if (messageId) {
                gsap.delayedCall(duration, () => sendMessage(messageId));
            }
        });

        const setup = (data) => {
            box.value = data.box;
            cards.value = data.box.pack.cards.map((card) => ({
                data: card,
                revealed: false,
                rotation: 0,
                flip: false,
                revealPr: 0,
            }));

            requestAnimationFrame(() => {
                user.value = data.user;
                rerip.value = data.rerip;

                updateStep(data.step);
                updateCard(data.card);

                events.emit(Setup, data);
            });

            // console.log('FanaticsProvider: Setting up box:', val);
        };

        const updateStep = (id) => {
            stepBase.value = id;

            events.emit(UpdateStep, id);
        };

        const updateCard = (data = {}) => {
            const hasIndex = typeof data.index === 'number';

            if (!hasIndex) {
                console.warn('FanaticsProvider: No index provided for card update');
                return;
            }

            const card = cards.value[data.index];

            if (data.active !== undefined) cardIndex.value = data.index;

            const keys = ['zoom', 'rotation', 'flip', 'direction', 'revealProgress', 'revealAngle'];

            keys.forEach((key) => {
                if (data[key] !== undefined) card[key] = data[key];
            });

            if (!card.revealed) card.revealed = data.revealed;

            if (data.meta) {
                Object.entries(data.meta).forEach(([key, value]) => {
                    card.data[key] = value;
                });
            }

            events.emit(UpdateCard, card);
        };

        const complete = () => {
            events.emit(Complete);
        };

        const FanaticsProvider = {
            box,
            user,
            ready,
            step,
            cardIndex,
            cards,
            currentCard,
            colorPaletteIndex,
            colorPalette,
            setColorPalette,
            sweat,
            lightsDown,
            rerip,
            events,
            setup,
            updateStep,
            updateCard,
            uiHeightRatio,
            sceneOffset,
            complete,
        };

        provide(FanaticsKey, FanaticsProvider);

        return FanaticsProvider;
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default FanaticsProvider;
